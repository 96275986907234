import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {logout} from "./authentication/authenticationUtil";

class Home extends Component {
    constructor(props) {
        super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;
    }

    render() {
        return (
            <div id="wrap" className={'home'}>
                {/*<div id="leftBar"></div>*/}
                {/*<header id="header">*/}
                {/*    <div className="header-wrap">*/}
                {/*        <h1 className="title">Header</h1>*/}
                {/*    </div>*/}
                {/*</header>*/}
                <div id="visual">
                    <div className="visual-wrap">

                        <div className="visual-info">
                            <h2 className="visual-title">인공지능 기반 IP 전략 서비스</h2>
                            <div className="visual-text">
                                자사와 경쟁사의 기술 동향 및 IP 포트폴리오를 한 눈에 확인하고<br/>R&D 계획 수립과 방향 설정에 활용해보세요.
                            </div>
                            <div className="visual-more"><a onClick={()=>{ this.props.history.push('/ipservice/simpleSearch') }}>서비스 이용하기</a></div>
                        </div>
                        <div className="visual-btn" style={{ display: this.session? 'none': 'block' }}>
                            <Link to={'/ipservice/login'} className="btn-login">로그인</Link>
                            <Link to={'/ipservice/member/join'} className="btn-join">회원가입</Link>
                        </div>
                        <div className="visual-user" style={{ display: this.session? 'block': 'none' }}>
                            { this.session && this.session.remain <= 0? '무료체험이 종료되었습니다.' : `무료체험 중입니다. (남은 기한 : ${this.session && this.session.remain}일)` }
                            <Link to={'/ipservice/member/memberInfo'}>
                                <span className="icon"><img src="images/main/visual_user_icon.png" alt=""/></span>
                            </Link>
                            <a onClick={logout.bind(this, this.props.history)}>
                                <span className="icon">Logout</span>
                            </a>
                        </div>
                        <div className="visual-image"><img src="images/main/visual_image.png" alt=""/></div>
                    </div>
                </div>
                <div id="main-content">
                    <div className="main-content-wrap">
                        <ul className="border-box">
                            <li>
                                <i><img src="images/main/box_image_01.png" alt=""/></i>
                                <strong>R&D 계획 수립</strong>
                                <span>글로벌 IP 분석을 통해 효과적인<br/>연구방향과 R&D 계획을 세워보세요.</span>
                            </li>
                            <li>
                                <i><img src="images/main/box_image_02.png" alt=""/></i>
                                <strong>기술 트렌드 파악</strong>
                                <span>기술트렌드와의 매칭정도를 확인하고 <br/>기술방향에 따른 특허전략을 수립해보세요</span>
                            </li>
                            <li>
                                <i><img src="images/main/box_image_03.png" alt=""/></i>
                                <strong>자사 및 경쟁사 분석</strong>
                                <span>경쟁사 대비 IP포트폴리오의 강약점과 <br/>자사 기술의 위치를 분석해보세요.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
