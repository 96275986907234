import React from 'react';
import ReactEcharts from "echarts-for-react";

const CorporateNationalityDistribution = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    return (
        <div className="service-tab-item active">
            <div className="service-title">기업 국적 분포</div>
            <div className="service-text">
                <p>어느 나라 기업(출원인)이 해당 제품분야의 개발을 활발히 진행하고 있는지 알 수 있습니다.</p>
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    tooltip: {
                                        trigger: 'item',
                                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                                    },
                                    legend: {
                                        orient: 'vertical',
                                        left: 10,
                                        data: ['기업1', '기업2', '기업3', '기업4', '기업5']
                                    },
                                    series: [
                                        {
                                            name: '국적별 출원건수',
                                            type: 'pie',
                                            radius: ['50%', '70%'],
                                            avoidLabelOverlap: false,
                                            label: {
                                                show: false,
                                                position: 'center'
                                            },
                                            emphasis: {
                                                label: {
                                                    show: true,
                                                    fontSize: '30',
                                                    fontWeight: 'bold'
                                                }
                                            },
                                            labelLine: {
                                                show: false
                                            },
                                            data: chartData.data
                                        }
                                    ]
                                }}
                                theme={'roma'}
                            />
                        ) : (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
        </div>
    );
}

export default CorporateNationalityDistribution;
