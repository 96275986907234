import React from 'react';
import ReactEcharts from "echarts-for-react";

const MajorTechnicalFieldDistribution = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    return (
        <div className="service-tab-item active">
            <div className="service-title">주요 기술분야 분포</div>
            <div className="service-text">
                <p>해당 제품분야의 주요 기술강도를 확인할 수 있습니다.</p>
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    tooltip: {},
                                    radar: {
                                        // shape: 'circle',
                                        name: {
                                            textStyle: {
                                                color: '#fff',
                                                backgroundColor: '#999',
                                                borderRadius: 3,
                                                padding: [3, 5]
                                            }
                                        },
                                        indicator: chartData.indicator
                                    },
                                    series: [{
                                        name: 'Budget vs spending',
                                        type: 'radar',
                                        data: [
                                            {
                                                value: chartData.data.value,
                                                name: '기술분야 분포'
                                            }
                                        ]
                                    }]
                                }}
                                theme={'roma'}
                            />
                        ) : (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
        </div>
    );
}

export default MajorTechnicalFieldDistribution;
